import * as React from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import ModalClose from "@mui/joy/ModalClose";
import deleted from "../../Assets/image/icons/deleted.png";
import { UndoWebinarData } from "../../Utils/services";
import { UndoUserData } from "../../Utils/services";
import { toast } from 'react-toastify';

export default function WebinarModel2({ turn, setTurn, webid, data1, singleuser, getAllUsers, setOpen }) {

  const handleset = async () => {
    setTurn(false);
    singleuser()
  };

  const handleundo = async () => {
    if (webid) {
      const result = await UndoWebinarData(webid)
      toast.success(result.res.message);
      setTurn(false);
      getAllUsers()

      setOpen(false)
      singleuser()
    }
    else if (data1) {
      const result = await UndoUserData(data1)
      getAllUsers()
      setTurn(false);
      setOpen(false)
      setTurn(false);
      singleuser()

    }
  }

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={turn}
        onClose={() => setTurn(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 650,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: 'calc(-1/4 * var(--IconButton-size))',
              right: 'calc(-1/4 * var(--IconButton-size))',
              boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
              borderRadius: '50%',
              border: '#808191',
              border: 2,
              bgcolor: 'white',
              color: '#808191'
            }}
          />
          <div id="modal-desc" textColor="text.tertiary">
            <div className="webinarmodel2  ">
              <div className="d-flex justify-content-center webinarmodel2img">
                <img src={deleted} alt="..." />
              </div>

              <div className="d-flex justify-content-center ">
                <h1 className="webinarmodalhllx">
                  Webinar deleted successfully.
                </h1>
              </div>
              <div className="modelbuttons">
                <botton
                  className="modlecanclebtn d-flex align-items-center justify-content-center"
                  style={{ cursor: "pointer" }}
                  onClick={handleundo}
                >
                  Undo
                </botton>
                <button className="modeldeletebtn" onClick={handleset}>Done</button>
              </div>
            </div>
          </div>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}
