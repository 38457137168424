import React,{useState,useEffect} from "react";
import rblogo from "../../Assets/image/Logos/Group 1.svg";
import mobile from "../../Assets/image/icons/calling.png";
import bell from "../../Assets/image/icons/bellimg.png";
import ritesh from "../../Assets/image/icons/ritesh.png"
import arrow from "../../Assets/image/icons/downarrow.png"
import logo from '../../Assets/image/Logos/toggle.svg'
import { getSingleUser } from "../../Utils/services";

const Header = (props) => {
  const handletoggle = props.handletoggle;
  const [exc, setexc] = useState([])

  const singleuser = async () => {
    const result = await getSingleUser()
    setexc(result.res.data)
  }

  useEffect(() => {
    singleuser()
  }, [])

  return (
    <header className="header d-flex align-items-center">
      <div className="d-flex  renewbuylogo">
        <img src={logo} alt="" onClick={() => { handletoggle() }} className="logoimage" />
        <img src={rblogo} alt="" />
      </div>
      <div className='profile'>
        <p className="samriti3">{exc.firstName} {exc.lastName} </p>
        <p className="samriti5">Welcome back, nice to see you again!</p>
      </div>

    </header>
  );
};

export default Header;
