import React , { useEffect, useMemo ,useState} from "react";
import delIcon from "../../../Assets/image/icons/delete.png"
import ColletralTable from "../../../Components/commoncomponent/ColletralTable";
import PeopleAddModel from "../../../Components/commoncomponent/PeopleAddModel";
import PeopleAddModelView from "../../../Components/commoncomponent/PeopleAddModelView"
import Pagination from "../../../Components/commoncomponent/Pagination";
import { getAllUser} from "../../../Utils/services"
import DeleteModel from "../../../Components/commoncomponent/DeleteModel";
import Spinner from '../../../Components/commoncomponent/Spinner'
import nodata from "../../../Assets/image/icons/nodata.svg"

function PeoplePage() {
  const [deleteitem,setdeleteitem]=useState();
  const [open, setOpen] = React.useState(false);
  const [model,setmodel]=useState(false)
  const [reopen,setreopen]=useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [exc,setexc]=useState([])
  const [data1, setdata1] = useState([]);
  const [viewData,setViewData] =useState()
  const [loading, setLoading] = useState(true)
  const [totalpage, settotalpage] = useState(0)

 const getAllUsers =async() =>{
  const result = await getAllUser({pageNumber:currentPage})
  setexc(await result.res.data.users);
  settotalpage(await result.res.data.totalPages)
  setdata1(await result.res.data.users);
  setLoading(false)
 }

 useEffect(()=>{
  getAllUsers()
 },[])

 useEffect(()=>{
  getAllUsers()
 },[currentPage])

  function handleView(data) {
    if(data){
      setreopen(true)
      setViewData(data)
    }
  }
  
  // const handleSearch = (event) => {
  //   const searchValue = event.target.value;
  //   const filterArray = exc.filter((e) => {
  //     return e.firstName.toLowerCase().includes(searchValue.toLowerCase()) || e.lastName.toLowerCase().includes(searchValue.toLowerCase()) ;
  //   });
  //   if (searchValue === "") {
  //     setdata1(filterArray);
  //   } else {
  //     setdata1(filterArray);
  //   }
  // };

  const FeedsColumns = useMemo(
    () => [
        {
            Header: "User Name",
            accessor: data1 => {
                return (
                    <>
                        <span>{data1?.firstName} {data1?.lastName}</span>
                    </>
                )
            }
        },
        {
            Header: "Contact Number",
            accessor: data1 => {
                return (
                    <>
                        <span>{data1?.contactNumber}</span>
                    </>
                )
            }
        },
        {
            Header: "Email",
            accessor: data1 => {
                return (
                    <>
                        <span>{data1?.email}</span>
                    </>
                )
            }
        },
        {
            Header: "Permissions",
            accessor: data1 => {
                return (
                    <>
                        <span>{data1?.roleId===2?"Checker":"Maker"}</span>
                    </>
                ) 
            }
        },
        {
            Header: 'Action',
            accessor: (data1) => {
                return (
                    <>
                     <div className="viewdeletebtn">
                        <span className="MuiButton-label editbtn"   onClick={() => handleView(data1)} >View</span>  
                                                                                                    
                        <span className="MuiButton-label underline ms-3" style={{cursor:'pointer'}}><img src={delIcon} alt="delete" onClick={()=>{setdeleteitem(data1.id);setmodel(true)}}/></span>
                        </div>
                    </>
                )
            },
        }
    ], [])

    const handleSearch = (event) => {
      const searchValue = event.target.value;
      const filterArray = exc.filter((e) =>
        e.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
        e.lastName.toLowerCase().includes(searchValue.toLowerCase())
      );
      setexc(filterArray);
    };

  return (
    <>
{loading ? <Spinner /> :
            <>
              {data1.length !== 0 ?
                 <div className="container-fluid">
                 <div className="row">
                   <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 ">
                     <div className=" flexmain-d">
                     <div className='searchInput'>
                     <input type="text" placeholder="Search Collaterals" onChange={handleSearch} />
             
                       </div>      
                       <div className="  d-pagination">            
                        <div class="pagination">
                         <Pagination
                           setCurrentPage={setCurrentPage}
                           currentPage={currentPage}
                           totalpage={totalpage}
                          //  onPageChange={handlePageChange}
                         />
                       </div>  
                         <div className="peopletableaddbtn">        
                           <button className='commanbtn' onClick={() => setOpen(true)}> Add + </button>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
                 <div className="row mt-2">
                   <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 mb-4  scolltable  ">
                     <ColletralTable data={data1} columns={FeedsColumns}  />
                   </div>
                 </div>
                 <PeopleAddModel setOpen={setOpen} open={open} getAllUsers={getAllUsers}/>
                 <PeopleAddModelView reopen={reopen} setreopen={setreopen} viewData={viewData}  />
               </div>:
                <div className="d-flex align-items-center justify-content-center">
                  <img src={nodata} alt="" srcset="" />
                </div>
              }
            </>
          }
    <DeleteModel model={model} setmodel={setmodel} data1={deleteitem} setexc={setexc} getAllUsers={getAllUsers} />
    </>
  );
}

export default PeoplePage;