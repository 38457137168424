import axios from 'axios'
import { MAKER_API } from '../../Utils/Routes';

const Create = async (userData) => {
    var token = localStorage.getItem("token");
    try {
        const response = await axios.post(MAKER_API, userData,{
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`
            },
          }
          ,{
            withCredentials: true
          })

        if (response.status === 200) {
            return { res: response.data.data }
        } else {
            return response.data;
        }

    } catch (err) {
        if (err.response) throw err.response.data;
        else throw err.message;
    }

}

const Maker = {
    Create
}

export default Maker;