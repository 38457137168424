import React, { useState } from "react";
import backarrow from "../../Assets/image/icons/backarrow.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { WebinarRegister } from "../../Utils/services";
import { toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";

function Registerpage() {

  const location = useLocation();
  let [loading, setLoading] = useState(false);
  const [data, setdata] = useState(location?.state?.props)
  const [formData, setFormData] = useState({ webinarId: data?.webId });


  console.log(location?.state?.props, "location?.state?.props");

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true)
    await WebinarRegister(formData)
      .then((res) => {
        toast.success(res.res.message)
        setLoading(false)
      }).catch((err) => {
        setLoading(false)
      })
    setFormData({ firstName: '', lastName: '', elCode: '' })
  };
  const onInputChange = (evt) => {
    let { name, value } = evt.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="container-fluid">
      <div className="d-flex">
        <div className="fillingupimg ms-2">
          <Link to="/webinar"><img src={backarrow} alt="..."></img></Link>
        </div>

        <div className="register-fillingup mt-1 ms-2">
          <Link to="/webinar">  <p>Register by filling up the details below.</p></Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 ">
          <div className="card cardregister">
            <div className="card-body cardregisterbody">
              <div className="cardregisterdata">
                <div className="registerimg">
                  <div className="cardregisterimage">
                    <img src={data?.image} alt="..."></img>
                  </div>
                </div>
                <div className="cardregistercontnet">
                  <h1 className="registercontent">{data?.Title}</h1>

                  <p className="jaysingh mt-3">{data?.host}({data?.Language})</p>

                  <p className="datefixing mt-3">{data?.webinarDate}</p>

                  <p className="timie mt-3"> {data?.webinarStartTime}- {data?.webinarEndTime} </p>
                  <p className="loremip mt-3">
                    {data?.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 ">
          <form onSubmit={handleSubmit} method="put" onChange={onInputChange}>
            <div className="mt-4 formic-flex">
              <div className=" formic ">
                <label>First Name</label>
                <input type="text" name="firstName" value={formData?.firstName}></input>
              </div>

              <div className="formic ">
                <label>Last Name</label>
                <input type="text" name="lastName" value={formData?.lastName}></input>
              </div>

              <div className="formic ">
                <label>EI Code</label>
                <input type="text" name="elCode" value={formData?.elCode}></input>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="mt-4">
        {loading ?
          <button className="btn-register" type="button" disabled>
            <ClipLoader color="white" loading={loading} size={15} aria-label="Loading Spinner" data-testid="loader" />
          </button>
          : <button type="submit" className="btn-register mt-2" onClick={handleSubmit}> <p>Register</p>  </button>}
      </div>

    </div>
  );
}

export default Registerpage;
