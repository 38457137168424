import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Button from '@mui/material/Button';
import { useState } from 'react';


const Calender = ({start,setstart,end,setend,filter,setShow}) => {
const handleclick=()=>{
  
 
  filter()
  setShow(false)
}
  return (
    <>
      <form>
        <div className="celendarbody">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
            <DesktopDatePicker
                id='fromcalendar-mi'
                label="From"
                value={start}
                onChange={(newValue) => {
                  setstart(dayjs(newValue).format('YYYY-MM-DD HH:mm'));
                }}
                renderInput={(params) => <TextField {...params} className="myDatePicker"/>}
              />
            </Stack>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3}>
              <DesktopDatePicker
                className='fromcalendar-mi'
                label="To"
                value={end}
                onChange={(newValue) => {
                  setend(dayjs(newValue).format('YYYY-MM-DD HH:mm'));
                }}
                renderInput={(params) => <TextField {...params} className="myDatePicker"/>}
              />
            </Stack>
          </LocalizationProvider>
          <Button variant="contained contained-mi" onClick={()=>handleclick()}>Submit</Button>
        </div>
      </form>
    </>
  );
}

export default Calender;