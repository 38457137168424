import React from "react";
import storeicon from "../../Assets/image/Rbstore/collertal.png";
import CardWebinarProduct from "./CardWebinarProduct";
import { useNavigate } from "react-router-dom";

import WebinarModel from "./WebinarModel";
const CardWebinar = (props) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handlechange = () => {
    navigate("/activitymonitor", { state: { props: props }})
  }

  return (
    <>
      <div className="">
        <div onClick={() => handlechange(props.webId)}>
          <CardWebinarProduct image={props.image} collertalimg={storeicon} Title={props.Title} description={props.description} webinarOn={props.webinarOn} Language={props.Language} webId={props.webId} webinarDate={props.webinarDate} host={props.host} webinarStartTime={props.webinarStartTime} webinarEndTime={props.webinarEndTime}/>
        </div>
        <div>
        </div>
      </div>
      <WebinarModel setOpen={setOpen} open={open} />
    </>
  );
};

export default CardWebinar;
