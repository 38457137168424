import moment from "moment";
import React from "react";
import ApprovalpandingModal from "./ApprovalpandingModal";
import PendingButton from "./PendingButton";

const Pastcard = (props) => {
  const [open, setOpen] = React.useState(false);

  const { singleuser } = props
  return (
    <>
      <div className="card Cheacker">
        <div className="card-body Cheackerbody">
          <div className="Cheackerdata">
            <div className="d-flex justify-content-center">
              <div className="abcd">
                <div className="cheackerimg">
                  <img src={props.image} alt="..." />
                </div>
              </div>
            </div>
            <div className="cheakercontent">
              <h2 className="cheackertitle">{props.Title}</h2>
              <div className="cheackerpass">
                <p className="mt-2">Leader:<span>Jay Singh</span></p>
                <p className="mt-2">Time: <span>11 AM - 1 PM</span></p>
                <p className="mt-2">Date: <span>{moment(props.webinarOn).format('DD-MM-YYYY')}</span></p>
                <p className="mt-2">Language:: <span>{props.Language}</span></p>
              </div>
              <PendingButton webId={props.webId} singleuser={singleuser} />
            </div>
          </div>
        </div>
      </div>
      <ApprovalpandingModal setOpen={setOpen} open={open} />
    </>
  );
};
export default Pastcard;
