import React, { useEffect } from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import ModalClose from "@mui/joy/ModalClose";
import WebinarModel2 from "./WebinarModel2";
import { DeleteWebinar } from "../../Utils/services";
import { toast } from 'react-toastify';

export default function WebinarModel({ open, setOpen, webid, singleuser }) {
  const [turn, setTurn] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleset = async () => {
    const result = await DeleteWebinar(webid)
    toast.success(result.res.message);
    // toast.success("success")
    setTurn(true);
    setOpen(false);

  };

  return (
    <>
      <React.Fragment>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={open}
          onClose={() => setOpen(false)}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 650,
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: 'calc(-1/4 * var(--IconButton-size))',
                right: 'calc(-1/4 * var(--IconButton-size))',
                boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                borderRadius: '50%',
                border: '#808191',
                border: 2,
                bgcolor: 'white',
                color: '#808191'
              }}
            />
            <div id="modal-desc" textColor="text.tertiary">
              <div className="webinarmodelbox ">
                <div className="d-flex justify-content-center">
                  <h1 className="webinarmodalh">
                    Are you sure you want to delete this webinar?
                  </h1>
                </div>
                <div className="modelbuttons">
                  <botton
                    className="modlecanclebtn d-flex align-items-center justify-content-center"
                    onClick={handleClose}
                  >
                    Cancel
                  </botton>
                  <button className="modeldeletebtn" onClick={handleset}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </Sheet>
        </Modal>
      </React.Fragment>
      <WebinarModel2 turn={turn} setTurn={setTurn} webid={webid} singleuser={singleuser} setOpen={setOpen} />
    </>
  );
}