import React, { useEffect, useMemo, useState } from "react";
import backarrow from "../../../Assets/image/icons/backarrow.png";
import logo from "../../../Assets/image/Rbstore/storeimg.png";
import datas from '../../../Components/commoncomponent/datas.json'
import ColletralTable from "../../../Components/commoncomponent/ColletralTable";
import Pagination from "../../../Components/commoncomponent/Pagination";
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom";
import { ExportExcel, RegisterAll } from "../../../Utils/services";
import FileSaver from "file-saver";

function ActivityMonitor() {
  const location = useLocation();
  const [field, setfield] = useState(location?.state?.props)
  const [currentPage, setCurrentPage] = useState(0);
  const [datas1, setdatas1] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const [totalpage, settotalpage] = useState(0)

  const [excel, setexcel] = useState([])
  const Webinarredister = async () => {
    const result = await RegisterAll({ webinarId: field?.webId, "pageNumber": 0, "pageSize": 10 })
    setdatas1(await result.res.data.registerPayloads);
    settotalpage(await result.res.data.totalPages)
    setfilterdata(await result.res.data.registerPayloads)
  }
  useEffect(() => {
    Webinarredister()
  }, [])

  useEffect(() => {
    Webinarredister()
  }, [currentPage])

  const form = new FormData();
  const handledownload = async () => {
    form.append("webinarId", field?.webId,)
    await ExportExcel(form).then((res) => {
      FileSaver.saveAs(res.res, "file.xlsx");
    }).catch((err) => {
      console.log(err)
    })
  }

  const FeedsColumns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: (datas1, item) => {
          return (
            <>
              <span>{item + 1}</span>
            </>
          )
        }
      },
      {
        Header: "Name",
        accessor: datas1 => {
          return (
            <>
              <span>{datas1?.firstName}{datas1?.lastName}</span>
            </>
          )
        }
      },
      {
        Header: "EI Code",
        accessor: datas1 => {
          return (
            <>
              <span>{datas1.elCode}</span>
            </>
          )
        }
      },
      {
        Header: "Registeration Date",
        accessor: datas1 => {
          return (
            <>
              <span>{datas1.registrationDate}</span>
            </>
          )
        }
      },
      {
        Header: 'Feedback',
        accessor: datas1 => {
          return (
            <>
              <span>{datas1.Feedback}</span>


            </>
          )
        },
      }

    ], [])

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    const filterArray = datas.filter((e) => {
      return e.Name.toLowerCase().includes(searchValue.toLowerCase()) || e.Feedback.toLowerCase().includes(searchValue.toLowerCase())
    }
    );
    setfilterdata(filterArray);
  };

  return (
    <div className="container-fluid">
      <div className="d-flex">
        <div className="fillingupimg ms-2">
          <Link to="/analysis"> <img src={backarrow} alt="..."></img></Link>
        </div>

        <div className="register-fillingup mt-1 ms-2">
          <Link to="/analysis"> <p className="gobacksize">Go Back</p></Link>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 ">
          <div className="card cardregister">
            <div className="card-body cardregisterbody">
              <div className="cardregisterdata">
                <div className="registerimg">
                  <div className="cardregisterimage">
                    <img src={field.image} alt="..."></img>
                  </div>
                </div>
                <div className="cardregistercontnet">
                  <h1 className="registercontent">{field?.Title} </h1>

                  <p className="jaysingh mt-3">{field?.host}({field?.Language})</p>

                  <p className="datefixing mt-3">{field?.webinarDate}</p>

                  <p className="timie mt-3"> {field?.webinarStartTime} - {field?.webinarEndTime}</p>
                  <p className="loremip mt-3">
                    {field?.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 ">
          <div className="mt-4 dflex-main">
            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12  main-first">
              <h1 className="activitymonitor">Activity Monitor</h1>
              <div className='searchInput'>
                <input type="text" placeholder='Search Collaterals' onChange={handleSearch} />
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 main-second">
              <button className="exportexcelbtn">
                <p onClick={handledownload}>Export to Excel</p>
              </button>
              <div class="pagination">
                <Pagination
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  totalpage={totalpage}
                //  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 mb-4 scolltable">
          <ColletralTable data={filterdata} columns={FeedsColumns} />
        </div>
      </div>
    </div>
  );
}

export default ActivityMonitor;
