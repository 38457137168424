import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Avatar from "../../../../Components/commoncomponent/Avatar";
import SwitchProfiledetails from "../../../../Components/commoncomponent/SwitchProfiledetails";
import { updatedata } from "../../../../Utils/services";
import { toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";
const UpdateProfile = ({
  merchant,
  collaterals,
  handleMarchant,
  handleCollateral,
}) => {
  const location = useLocation();
  let [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(location?.state?.props);
  // const [data, setdata] = useState(location?.state?.props)
  const form = new FormData();
  const navigate = useNavigate();

  // const handleGoProfile = (e) => {
  //   navigate("/profile");
  // };
  const handleChangePasword = () => {
    navigate("/profile/changepassword");
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true)
    form.append("firstName", formData.firstName);
    form.append("lastName", formData.lastName);
    form.append("contactNumber", formData.contactNumber);
    form.append("email", formData.email);
    form.append("roleId", formData.roleId);
    const result = await updatedata(form);
   setLoading(false)
    toast.success(result.res.message)

    navigate("/profile");

  };

  const onInputChange = (evt) => {
    let { name, value } = evt.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="profiler">
      {/* -----------------tab button -------------------------------- */}

      <SwitchProfiledetails
        Information="productactive"
        handleInformation={handleChangePasword}
      />

      {/* --------------- profile page ---------------------------------- */}

      <Avatar />

      {/* ---------------- avatar details---------------------------------- */}

      <div className="profile_form_details ">
        <div className="">
          <form action="" onSubmit={handleSubmit} method="put" onChange={onInputChange}>

            <div className="row ">
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                <div className="">
                  <p>First Name</p>
                  <input
                    value={formData?.firstName}
                    type="text"
                    className="form-control"
                    placeholder="Samriti"
                    name="firstName"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                <div>
                  <p>Last Name</p>
                  <input
                    value={formData?.lastName}
                    type="text"
                    className="form-control"
                    placeholder="Sharma"
                    name="lastName"

                  />
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                <div>
                  <p>Designation</p>
                  <input
                    value={formData?.roleNameString}
                    type="text"
                    className="form-control"
                    placeholder="Manager, Digital Marketing"
                    name="roleNameString"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                <div className="">
                  <p>Contact Number</p>
                  <input
                    value={formData?.contactNumber}
                    type="text"
                    className="form-control"
                    placeholder="+91-8506073332"
                    name="contactNumber"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                <div>
                  <p>Email</p>
                  <input
                    value={formData.email}
                    type="text"
                    className="form-control"
                    placeholder="samritisharma@gmail.com"
                    name="email"
                  />
                </div>
              </div>
            </div>
          </form>

        </div>
        <div className="row">
          <div
            className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 profile_editbtn mt-4"
            
          >
            {loading ?
                      <button className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4" type="button" disabled>
                        <ClipLoader color="#fffff" loading={loading} size={15} aria-label="Loading Spinner" data-testid="loader" />
                      </button>
                      : <button className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4" type="submit" onClick={handleSubmit}> Update  </button>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfile;
