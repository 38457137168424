import React, { useState } from 'react'
import crossicon from "../../Assets/image/Collateral/cross.png"
import yesicon from "../../Assets/image/Collateral/yes.png"
import { approvewebinar } from '../../Utils/services'
import { declineewbinar } from '../../Utils/services'
import { toast } from 'react-toastify';

const PendingButton = ({ webId, singleuser }) => {
    const [show, setShow] = useState(true);
    const [declineDetails, setDeclineDetails] = useState(false);
    const [approved, setApproved] = useState(false)

    const handleDecline = () => {
        setShow(false)
        setDeclineDetails(true)
        setApproved(false)
    }

    const handleCross = () => {
        setShow(true)
        setDeclineDetails(false)
        setApproved(false)
    }

    const handaleYes = async () => {
        const result = await declineewbinar({
            "webId": webId,
        });
        setShow(true)
        setDeclineDetails(false)
        setApproved(false)
        toast.success("Webinar is Decline successfully")
        singleuser()
    }

    const handAproved = () => {
        setShow(false)
        setApproved(true)
        setDeclineDetails(false)
    }

    const handleApprovedApi = async () => {
        const result = await approvewebinar({
            "webId": webId,
        })
        setShow(true)
        setDeclineDetails(false)
        setApproved(false)
        toast.success('Webinar is approved successfully')
        singleuser()
    }

    return (
        <React.Fragment>
            {
                show && <div className='declineapprovebtn d-flex '>

                    <button className='declinebtnn me-3' onClick={handleDecline}  >Deacline</button>
                    <button className='approvebtnn ' onClick={handAproved}>Approved</button>
                </div>
            }
            {
                declineDetails &&
                <div className='d-flex align-items-center decline-details declineapprovebtn'>
                    <span className='me-3'>Decline Collateral?</span>
                    <img src={crossicon} alt="" className='cursor-pointer me-3' onClick={handleCross} />
                    <img src={yesicon} alt="" className='cursor-pointer' onClick={handaleYes} />
                </div>
            }
            {
                approved &&
                <div className='d-flex align-items-center approve-details declineapprovebtn'>
                    <span className='me-3'>Approve Collateral?</span>
                    <img src={crossicon} alt="" className='cursor-pointer me-3' onClick={handleCross} />
                    <img src={yesicon} alt="" className='cursor-pointer' onClick={handleApprovedApi} />
                </div>
            }
        </React.Fragment >
    )
}
export default PendingButton