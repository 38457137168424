export const LOGIN = "/auth/login"; 
export const MAKER_API= "/webinar/create";
export const Create_User="/user/create";
export const Get_AllUser="/user/get/all?pageNumber=";
export const GetSingleUser="/user/get";
export const deletedata="/user/delete/";
export const UserUndo="/user/undo/delete/";
export const UserUpdate="/user/update";
export const checkpassword="/user/checkPassword";
export const GetSingleWebinar="/webinar/get/";
export const WebinarGetAll="/webinar/get/all";
export const Approvewebinar="/webinar/approve";
export const Declineewbinar="/webinar/decline";
export const webinarDelete="/webinar/delete/";
export const WebinarUndo="/webinar/undo/delete/";
export const updatepassword_endpoint="/user/changePassword";
export const EditWabinar="/webinar/update";
export const RegisterWebinar="/register/webinar";
export const RegisterGetAll="/register/get/all";
export const ExportToExcel="/register/exportToExcel";