import React from 'react'

const SwitchProduct = ({ merchant, collaterals, handleCollateral, handleMarchant }) => {


    return (
        <div className="product-service d-flex">
            <div className={`${merchant} merchandise-libary `} onClick={() => handleMarchant()}>
                <h4 className=" pb-4 px-2">Upcoming Webinars</h4>
            </div>
            <div className={`collaterals-lab  ${collaterals}`} onClick={() => handleCollateral()}>
                <h4 className=" pb-4  ">Analytics </h4>
            </div>
        </div>
    )
}

export default SwitchProduct
