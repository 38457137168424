import React, { useState } from "react";
import storeicon from "../../Assets/image/Rbstore/collertal.png";
import CardWebinarProduct from "./CardWebinarProduct";
import download from "../../Assets/image/icons/icon-edit.png";
import share from "../../Assets/image/icons/icon-delete.png";
import { Link, useNavigate } from "react-router-dom";

import WebinarModel from "./WebinarModel";
const CardWebinar = (props) => {
  const [open, setOpen] = useState(false);

  const sendprops = {
    image: props.image,
    Title: props.Title,
    webinarOn: props.webinarOn,
    Language: props.langName,
    webId: props.webId,
    host: props.host,
    description: props.description,
    webinarDate: props.webinarDate,
    webinarStartTime: props.webinarStartTime,
    webinarEndTime: props.webinarEndTime,
    myImageName: props.myImageName,
    link: props.link,
    langID: props.langID
  };
  // delete sendprops["singleuser"]

  const navigate = useNavigate();

  const handleedit = (e) => {
    navigate('/editwebinar', { state: { props: sendprops } })
  }

  const handleregister = (e) => {
    navigate('/registerpage', { state: { props: sendprops } })
  }

  return (
    <>
      <div className="">
        <CardWebinarProduct collertalimg={storeicon} Title={props.Title} webinarOn={props.webinarOn} Language={props.langName} webId={props.webId} host={props.host} description={props.description} webinarDate={props.webinarDate} webinarStartTime={props.webinarStartTime} webinarEndTime={props.webinarEndTime} myImageName={props.myImageName} image={props.image} />
        <div>
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex registbody">
            <div to="/registerpage" style={{ width: '100%' }}><button className="registerbtn" onClick={() => handleregister(props.webId)}>Register</button></div>
            <div className="d-flex twobtn">
              <button className="webinareditbtn" onClick={() => handleedit(props.webId)} >
                <img src={download} alt=".." className="" />
              </button>
              <button className="webinardeletebtn" onClick={() => setOpen(true)} >
                <img src={share} alt="..." />
              </button>
            </div>
          </div>
        </div>
      </div>
      <WebinarModel setOpen={setOpen} open={open} webid={props.webId} singleuser={props.singleuser} />
    </>
  );
};
export default CardWebinar;