import * as React from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import deleted from "../../Assets/image/icons/deleted.png";
export default function MakerModel({ turn, setTurn }) {
  const handleClose = () => {
    setTurn(false);
  };
  return (
    <React.Fragment>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={turn}
        onClose={() => setTurn(false)}
        sx={{ padding: "20px" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
        >
          <div id="modal-desc" textColor="text.tertiary">
            <div className="webinarmodel2  ">
              <div className="d-flex justify-content-center webinarmodel2img">
                <img src={deleted} alt="..." />
              </div>

              <div className="d-flex justify-content-center ">
                <h1 className="webinarmodalhllx">
                Webinar created succesfully.
                </h1>
              </div>
              <div className="modelbuttons">
                <botton
                  className="modlecanclebtn d-flex align-items-center justify-content-center"
                  onClick={handleClose}
                >
                  Undo
                </botton>
                <button className="modeldeletebtn">Done</button>
              </div>
            </div>
          </div>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
}
