import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import view from "../../../../Assets/image/Profile/view.png";
import Avatar from "../../../../Components/commoncomponent/Avatar";
import SwitchProfiledetails from "../../../../Components/commoncomponent/SwitchProfiledetails";
import { PasswordCheck } from "../../../../Utils/services";
import { toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";

const ProfilePassword = () => {
  const [viewpassword, setPassword] = useState(true);
  const [formData, setFormData] = React.useState({});
  let [loading, setLoading] = useState(false);

  const form = new FormData();
  const navigate = useNavigate();
   
  // const handleUpadatePasswordpage = (e) => {
  //   navigate("/profile/updatepassword");
  // };
   
  const handleChangePasword = () => {
    navigate("/profile");
  };

  const onInputChange = (evt) => {
    let { name, value } = evt.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmitform = async (e) => {
    e.preventDefault();
    setLoading(true)

    if (formData.password) {
      form.append("oldPassword", formData.password);
      await PasswordCheck(form).then((res) => {
        setLoading(false)

        navigate("/profile/updatepassword", { state: { props: formData.password } });
      }).catch(err => alert(err.message))
    } else {
      toast.error("Enter the Old password")

    }

  }

  return (
    <div className="profiler">
      {/* -----------------tab button -------------------------------- */}

      <SwitchProfiledetails
        Password="productactive"
        handleChangePassword={handleChangePasword}
      />

      {/* --------------- profile page ---------------------------------- */}

      <Avatar />

      {/* ---------------- avatar details---------------------------------- */}

      <div className="profile_form_details  mt-4">
        <div className="">
          <form onSubmit={onSubmitform} onChange={onInputChange}>
            <div className="row ">
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                <div className="">
                  <p>Current Password</p>
                  <div className="passwordview">
                    <input
                      type={viewpassword ? "password" : "text"}
                      className="form-control"
                      placeholder="Enter old password"
                      // value={1234567886932323}
                      name="password"

                    />
                    <img
                      src={view}
                      alt=""
                      className="viewpassword"
                      onClick={() => setPassword(!viewpassword)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div
            className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 profile_editbtn mt-4"
          >
            {loading ?
              <button className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4" type="button" disabled>
                <ClipLoader color="#fffff" loading={loading} size={15} aria-label="Loading Spinner" data-testid="loader" />
              </button>
              : <button className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4" type="submit" onClick={onSubmitform}>    Change Password </button>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePassword;
