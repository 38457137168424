import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../../Components/commoncomponent/Avatar";
import SwitchProfiledetails from "../../../../Components/commoncomponent/SwitchProfiledetails";
import { getSingleUser } from "../../../../Utils/services";
import Spinner from '../../../../Components/commoncomponent/Spinner'
import nodata from "../../../../Assets/image/icons/nodata.svg"

const Profile = ({
  merchant,
  collaterals,
  handleMarchant,
  handleCollateral,
}) => {

  const [exc, setexc] = useState([])
  const [data, setdata] = useState()
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate();

  const handleUpdateDetailpage = (e) => {
    navigate("/profile/update",
    { state: { props: exc } })
  };

  const handleChangePasword = () => {
    navigate("/profile/changepassword");
  };


  const singleuser = async () => {
    const result = await getSingleUser()
    setexc(result.res.data)
    setLoading(false)


  }

  useEffect(() => {
    singleuser()
  }, [])

  const onInputChange = (evt) => {
    let { name, value } = evt.target;
    setdata({ ...data, [name]: value });

  }

  return (

<>

{loading ? <Spinner /> :
            <>
              {exc.length !== 0 ?
               <div className="profiler">
               {/* -----------------tab button -------------------------------- */}
         
               <SwitchProfiledetails
                 Information="productactive"
                 handleInformation={handleChangePasword}
               />
         
               {/* --------------- profile page ---------------------------------- */}
         
               <Avatar />
         
               {/* ---------------- avatar details---------------------------------- */}
         
               <div className="profile_form_details ">
                 <form onChange={onInputChange}>
         
                   <div className="row ">
                     <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                       <div className="">
                         <p>First Name</p>
                         <input
                           type="text"
                           className="form-control"
                           placeholder=""
                           name="firstName"
                           value={exc.firstName}
                         />
                       </div>
                     </div>
                     <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                       <div>
                         <p>Last Name</p>
                         <input
                           type="text"
                           className="form-control"
                           placeholder="Sharma"
                           name="lastName"
                           value={exc.lastName}
                         />
                       </div>
                     </div>
                     <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                       <div>
                         <p>Designation</p>
                         <input
                           type="text"
                           name="roleNameString"
                           className="form-control"
                           placeholder="Manager, Digital Marketing"
                           value={exc.roleNameString}
                         />
                       </div>
                     </div>
                     <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                       <div className="">
                         <p>Contact Number</p>
                         <input
                           type="text"
                           name="contactNumber"
                           className="form-control"
                           placeholder="+91-8506073332"
                           value={exc.contactNumber}
                         />
                       </div>
                     </div>
                     <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
                       <div>
                         <p>Email</p>
                         <input
                           type="text"
                           name="email"
                           className="form-control"
                           placeholder="samritisharma@gmail.com"
                           value={exc.email}
                         />
                       </div>
                     </div>
                   </div>
                 </form>
                 <div className="row">
                   <div
                     className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 profile_editbtn  mt-4"
                     onClick={handleUpdateDetailpage}
                   >
                     <button className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
                       Edit
                     </button>
                   </div>
                 </div>
               </div>
             </div> :
                <div className="d-flex align-items-center justify-content-center">
                  <img src={nodata} alt="" srcset="" />
                </div>
              }
            </>
          }


    </>
  );
};

export default Profile;
