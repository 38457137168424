import * as React from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import ModalClose from "@mui/joy/ModalClose";
import DeleteModel2 from "./WebinarModel2";
import { deleteddata } from "../../Utils/services"
import { toast } from 'react-toastify';


export default function DeleteModel({ model, setmodel,data1,setexc ,getAllUsers }) {
  const [turn, setTurn] = React.useState(false);
  const handleClose = () => {
    setmodel(false);
  };
  const handleset = () => {
    setTurn(true);
    setmodel(false);
  };

    
  const handledelete = async (item)=>{
    const reqdelete=await deleteddata(item)
    toast.success(reqdelete.res.message)
    getAllUsers()
    handleset()

  };

  return (
    <>
      <React.Fragment>
      <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={model}
          onClose={() => setmodel(false)}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 650,
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
            }}
          >
          <ModalClose
            variant="outlined"
            sx={{
              top: 'calc(-1/4 * var(--IconButton-size))',
              right: 'calc(-1/4 * var(--IconButton-size))',
              boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
              borderRadius: '50%',
              border: '#808191',
              border:2,
              bgcolor: 'white',
              color:'#808191'
            }}
          />  
            <div id="modal-desc" textColor="text.tertiary">
              <div className="webinarmodelbox ">
                <div className="d-flex justify-content-center">
                  <h1 className="webinarmodalh">
                    Are you sure you want to delete this webinar?
                  </h1>
                </div>
                <div className="modelbuttons">
                  <botton
                    className="modlecanclebtn d-flex align-items-center justify-content-center"
                    onClick={handleClose}
                  >
                    Cancel
                  </botton>
                  {/* onClick={handleset} */}
                  <button className="modeldeletebtn" onClick={()=>handledelete(data1)} >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </Sheet>
        </Modal>
      </React.Fragment>
      <DeleteModel2 turn={turn} setTurn={setTurn} data1={data1} getAllUsers={getAllUsers}/>
    </>
  );
}