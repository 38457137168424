import React, { useState } from 'react';
import prev from '../../Assets/image/icons/prev.svg';
import next from '../../Assets/image/icons/next.svg';

function Pagination({ setCurrentPage, currentPage, onPageChange ,totalpage}) {
// console.log(currentPage,"curent");
  return (
    <div className='pagination d-flex align-items-center '>
      {(totalpage!==0?currentPage+2:totalpage) >  (totalpage) && (
        <div onClick={() => setCurrentPage(currentPage - 1)}>
          <img src={prev} alt='Previous page' />
        </div>
      )}
      <div className='px-4 pt-1 me-3 d-flex align-items-center'>
        <p className='d-flex align-items-center'><span>{(totalpage!==0?currentPage+1:totalpage)}</span>-<span>{totalpage}</span></p>
        <p className='ps-3'>of</p>
        <p className='ps-3'>{totalpage}</p>
      </div>
      {(totalpage!==0?currentPage+1:totalpage) < totalpage && (
        <div onClick={() => setCurrentPage(currentPage + 1)}>
          <img src={next} alt='Next page' />
        </div>
      )}
    </div>
  );
}

export default Pagination;


