import React from 'react'
import avtar from "../../Assets/image/Profile/avatar.png";


const Avatar = () => {

    return (

        <div className='profilepage mt-4 d-flex align-items-center'>
            <div className='profile_Avatar me-4'>
                <img src={avtar} alt="" />

            </div>
            <div className='profile_avtar_details'>
                <h3>Samriti Sharma</h3>
                <p>Checker, Digital Marketing</p>

            </div>

        </div>

    )
}

export default Avatar