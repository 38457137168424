import React, { useState, useEffect } from "react";
import Input from "@mui/material/Input";
import logo from "../../../Assets/image/icons/copyingtext.png";
import attachfile from "../../../Assets/image/icons/attachfile.png";
import TextField from "@mui/material/TextField";
import { useDropzone } from 'react-dropzone'
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import MakerModel from "../../../Components/commoncomponent/MakerModel";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useForm } from "react-hook-form";
import Maker from "../../../Redux/maker/makerService";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

const ariaLabel = { "aria-label": "description" };
const Makerboard = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [text, setText] = useState(true) 
  const [file, setFiles] = useState([])
  const [valueable, setValueable] = useState();
  const [copied, setCopied] = useState();
  const [formData, setFormData] = useState({});
  const [image, setimage] = useState();
  const [start, setstart] = useState(dayjs());
  const [date, setdate] = useState()
  const [turn, setTurn] = useState(false);
  const [time, settime] = useState()
  const [endtime, setendtime] = useState()

  let [loading, setLoading] = useState(false);

  const form = new FormData();

  const handleDateChange = (newValue) => {
    const newDateTime = dayjs(newValue).set('hour', start.hour()).set('minute', start.minute());
    setstart(newDateTime);
    setdate(newDateTime.format('YYYY-MM-DD HH:mm'))
  };

  const handleTimeChange = (newValue) => {
    const newDateTime = dayjs(start).set('hour', newValue.hour()).set('minute', newValue.minute());

    const newDateTimePlusOneHour = newDateTime.add(1, 'hour');
    setstart(newDateTime);
    setendtime(newDateTimePlusOneHour.format('HH:mm'))
    settime(newDateTime.format('HH:mm'))
    setdate(newDateTime.format('YYYY-MM-DD HH:mm'))
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    accept: {
      "application/pdf": ['.pdf'],
      'image/jpeg': ['.jpeg', '.png', ".jpg"]

    },
    onDrop: (acceptedFiles) => {
      setimage(acceptedFiles[0])
      setFiles(
        <li key={acceptedFiles[0].path}>
          {acceptedFiles[0].path}
        </li>
      );
      setText(false)
    }
  });

  const onSubmit = async (evt) => {
    if (
      !formData.title ||
      !formData.host ||
      !formData.link ||
      !formData.password ||
      !formData.fav_language ||
      !formData.description ||
      !time ||
      !formData.option ||
      !date ||
      !image
    ) {
      toast.error("Please fill in all the fields.");
      return;
    }

    form.append("title", formData.title);
    form.append("host", formData.host);
    form.append("link", formData.link);
    form.append("password", formData.password);
    form.append("mandatory", formData.fav_language);
    form.append("description", formData.description);
    form.append("webinarStartTime", time);
    form.append("webinarEndTime", endtime);
    form.append("langID", parseInt(formData.option));
    form.append("webinarOn", date);
    form.append("imageName", image);
    setLoading(true);

    try {
      const result = await Maker.Create(form);

      reset({
        link: "",
        langID: "",
        title: "",
        host: "",
        password: "",
        description: "",
        webinarOn: "",
        image: "",
        mandatory: "",
        date: "",
        time: "",
      });

      toast.success("Webinars are fetched successfully");
      navigate("/cheackerboard");
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }

    setLoading(false);
  };

  const onInputChange = (evt) => {
    let { name, value } = evt.target;
    if (evt.target.type === "checkbox") {
    }
    setFormData({ ...formData, [name]: value });
  };

  const otherChange = (e) => {
    setValueable(e.target.value);
  };

  const otherCopy = () => setCopied(true);
  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 500);
  }, [copied]);

  const closemodel = () => {
    navigate("/makerboard")
    reset({ link: "", langID: "", title: "", host: "", password: "", description: "", webinarOn: "", image: "", mandatory: "", date: "", time: "", });
    setLoading(false)
  }

  const languages = [
    { id: 6, label: 'English' },
    { id: 7, label: 'Hindi' },
    { id: 8, label: 'Punjabi' },
    { id: 9, label: 'Tamil' },
    { id: 10, label: 'Telugu' },
    { id: 11, label: 'Oriya' },
    { id: 12, label: 'Marathi' },
    { id: 13, label: 'Bengali' },
    { id: 14, label: 'Kannada' },
  ];
  return (
    <>
      <div>
        <h1 className="editwebianr1">Add Webinar</h1>
      </div>
      <div className="">
        <form action="" onSubmit={handleSubmit(onSubmit)} method="post" onChange={onInputChange}>
          <div className="row ">
            <div className="col-lg-7 col-md-8 col-sm-12 col-12">
              <div className="mt-4  webinarinputtext">
                <div>
                  <p>Webinar Title</p>
                  <Input
                    placeholder="type something..."
                    inputProps={ariaLabel}
                    className="webinartitleinput mt-3"
                    name="title"
                    {...register("title", { required: true, maxLength: 20 })}
                  />
                  {errors?.title?.type === "required" && <p className="validationform">Please enter the title</p>}
                </div>
                <div>
                  <p className="mt-4">Webinar Host</p>
                  <Input
                    placeholder="type something..."
                    inputProps={ariaLabel}
                    className="webinartitleinput mt-3"
                    name="host"
                    {...register("host", { required: true, maxLength: 20 })}
                  />
                  {errors?.host?.type === "required" && <p className="validationform">This field is required</p>}
                </div>
                <div>
                  <p className="mt-4 d-flex">Webinar Link</p>
                  <div className="d-flex">
                    <Input
                      placeholder="type something..."
                      inputProps={ariaLabel}
                      className="webinartitleinput mt-3"
                      name="link"
                      {...register("link", { required: true })}
                      value={valueable}
                    // onChange={(e) => setValueable(e.target.value)} 
                    />
                    <div className="imagerelativre">
                      <CopyToClipboard text={valueable}    >
                        <img src={logo} alt="..." className="ms-3 mt-3" onClick={otherCopy} />
                      </CopyToClipboard>
                      {copied && <span className="imageposition">Copied</span>}
                    </div>
                  </div>
                  {errors?.link && <p className="validationform">Link is required</p>}
                </div>

                <div>
                  <p className="mt-4">Webinar Password</p>
                  <Input
                    placeholder="type something..."
                    inputProps={ariaLabel}
                    className="webinartitleinput mt-3"
                    name="password"
                    {...register("password", { required: true, maxLength: 20 })}
                  />
                  {errors?.password?.type === "required" && <p className="validationform">please enter password</p>}
                </div>
                <div>
                  <div className="d-flex align-items-center">
                    <p className="mt-4" style={{ fontSize: '16px' }}>Mandatory</p>
                    <div className="mt-4 ms-3">
                      <input
                        type="radio"
                        id="html"
                        className="ux-btn-group-item"
                        name="fav_language"
                        value="yes"
                        {...register("fav_language", { required: true })}
                      />
                      <label className="ms-1">Yes</label>
                      <input
                        type="radio"
                        id="css"
                        className="ux-btn-group-item ms-3"
                        name="fav_language"
                        value="no"
                        {...register("fav_language", { required: true })}
                      />
                      <label className="ms-1">No</label>
                    </div>
                  </div>
                  {errors?.fav_language && (
                    <p className="validationform">Please select an option for mandatory.</p>
                  )}
                </div>

              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12  mt-5">
              <div className="d-flex align-items-center justify-content-center">
                <div className="browserbox d-flex align-items-center justify-content-center">
                  <div className="browserboxinner d-flex align-items-center justify-content-center">
                    <div className="uploadfile">
                      <div>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} name="image" onInput={onInputChange}  {...register('image', { required: true })} />
                          {isDragActive ?
                            <p>Drop Image Here</p> : <>
                              <div className="browsertext"><img src={attachfile} alt=".." className="ms-3" />  <p>Browser</p></div>
                            </>}
                          {isDragReject && "File type not accepted, sorry!"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-1">
                {text ? <h1 className="pdfjpeg">PDF,JPEG,etc</h1> :
                  <ul className='text-center'>{file}</ul>}

              </div>
              <div className="d-flex align-items-center justify-content-center mt-1">
                {errors?.image?.type === "required" && !image && (
                  <p className="validationform">Please select image</p>
                )}
              </div>
            </div>
          </div>
          <div className="webinarinputtext  mt-4">
            <p>Choose Language</p>
          </div>
          <div className="row ">
            <div className="col-md-11 mt-3 languagesover">
              <ul className="ux-btn-group">
                {languages.map(language => (
                  <li key={language.id}>
                    <input
                      type="radio"
                      id={language.id}
                      className="ux-btn-group-item"
                      name="option"
                      value={language.id}
                      {...register("option", { required: true })}
                    />
                    <label htmlFor={language.id}>{language.label}</label>
                  </li>
                ))}
              </ul>
            </div>
            {errors?.option?.type === "required" && <p className="validationform ms-4" >please select the language</p>}
          </div>
          <div className="row">
            <div className="col-md-10">
              <div className="webinarinputtext  mt-2 ">
                <p>Description</p>
                <div className=" mt-2">
                  <textarea rows="5" className="textarea" name="description"  {...register("description", { required: true })}></textarea>
                </div>
                {errors?.description?.type === "required" && <p className="validationform">please enter description</p>}
              </div>
            </div>
          </div>
          <div className="webinarinputtext  mt-3 ">
            <p>Schedule Date & Time</p>
            <div className="mt-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>

                <Stack spacing={2}>
                  <div className=" datetimepicker">
                    <div>
                      <DesktopDatePicker
                        id='fromcalendar-mi'
                        label="From"
                        time="date"
                        value={start.format('YYYY-MM-DD')}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} className="myDatePicker" />}
                      />
                    </div>
                    <div className=" imepickerr">
                      <TimePicker
                        value={start}
                        label="Time"
                        name="time"
                        onChange={handleTimeChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                  </div>
                </Stack>
              </LocalizationProvider>
            </div>
          </div>
          <div className="mt-4 sacecanclebtn">
            {loading ?
              <button className="savebuttonedit" type="button" disabled>
                <ClipLoader color="#fffff" loading={loading} size={15} aria-label="Loading Spinner" data-testid="loader" />
              </button>
              : <button type="submit" className="savebuttonedit" onClick={onSubmit}> Save  </button>}



            <button className="canclebuttonedit ms-4" type="button" onClick={closemodel}>Cancel</button>
          </div>
        </form>
      </div>
      <MakerModel turn={turn} setTurn={setTurn} />
    </>
  );
}
export default Makerboard;