import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import view from "../../../../Assets/image/Profile/view.png";
import Avatar from "../../../../Components/commoncomponent/Avatar";
import SwitchProfiledetails from "../../../../Components/commoncomponent/SwitchProfiledetails";
import { update_password } from "../../../../Utils/services";
import { toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";

const Updatepassword = ({
  merchant,
  handleMarchant,
  collaterals,
  handleCollateral,
}) => {
  const [viewpassword, setPassword] = useState(true);
  const navigate = useNavigate();
  const [newPassword, setnewpassword] = useState()
  const [reenter, setreenter] = useState()
  const [error, setError] = useState("");
  const location = useLocation();
  let [loading, setLoading] = useState(false);

  const handleChangePasword = () => {
    navigate("/profile");
  };

  const handleUpdate = async () => {
    setLoading(true)

    if (newPassword !== reenter) {
      setError("Passwords do not match");
      return;
    }

    const result = await update_password({
      "oldPassword": location?.state?.props,
      "newPassword": newPassword
    })
    setLoading(false)

    toast.success(result.res.message, "checkdataupdate")
    navigate("/profile/changepassword");


    // .then((res)=>{
    // navigate("/profile/changepassword");
    // }).catch((err)=>{
    // })

  };
  return (
    <div className="profiler">
      {/* -----------------tab button -------------------------------- */}

      <SwitchProfiledetails
        Password="productactive"
        handleChangePassword={handleChangePasword}
      />

      {/* --------------- profile page ---------------------------------- */}

      <Avatar />

      {/* ---------------- avatar details---------------------------------- */}

      <div className="profile_form_details ">
        <div className="">
          <div className="row ">
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
              <div className="">
                <p>Current Password</p>
                <div className="passwordview">
                  <input
                    type={viewpassword ? "password" : "text"}
                    className="form-control"
                    placeholder=""
                    value={location?.state?.props}
                    disabled
                  />
                  <img
                    src={view}
                    alt=""
                    className="viewpassword"
                    onClick={() => setPassword(!viewpassword)}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
              <div className="">
                <p>New Password</p>
                <div className="passwordview">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => setnewpassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mt-4">
              <div className="">
                <p>Re-Type New Password</p>
                <div className="passwordview">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Re-enter new password"
                    value={reenter}
                    onChange={(e) => setreenter(e.target.value)}
                  />
                </div>
              </div>
              <span className="error">{error}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 profile_editbtn mt-4"

          >

            {loading ?
              <button className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4" type="button" disabled>
                <ClipLoader color="#fffff" loading={loading} size={15} aria-label="Loading Spinner" data-testid="loader" />
              </button>
              : <button className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4" type="submit" onClick={handleUpdate}> Update Password  </button>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Updatepassword;
