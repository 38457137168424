import React,{useEffect, useState} from 'react'
import Header from './Header'
import MainWrapper from './MainWrapper'
import Sidenav from './Sidenav'


const Layout = () => {

  const [toggle,setToggle]=useState(true)
  const [toggle2,setToggle2]=useState(true)
  
  
  const handletoggle=()=>{
    if(toggle2){
      setToggle(!toggle)
    }
    
  }
  
// <-----------code to get the current screen size and chnage the toggle of sidebar------------>
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}


  // const width = window.innerWidth;
  useEffect(()=>{
      if(windowSize.innerWidth<769){
        setToggle(false)
        setToggle2(true)
      }
      else if(windowSize.innerWidth>769){
        setToggle(true)
        setToggle2(false)
      }
  },[windowSize.innerWidth])

// <--------End------->

  
  return (
    <>
      <Header handletoggle={handletoggle}/>
      <div className="Layout d-flex ">
        <div className={toggle?'side-nav':'side-nav1'}>
          <Sidenav toggle={toggle} handletoggle={handletoggle}/>
        </div>
        <div className={toggle?"page-container":"page-container1"}>
          <MainWrapper />
        </div>
      </div>

    </>
  )
}

export default Layout