import React from "react";
import { Route, Routes } from "react-router-dom";
import Webinar from "../../Pages/webinar";
import Analysis from "../../Pages/webinar/Analysis";
import ActivityMonitor from "../../Pages/webinar/Analysis/ActivityMonitor";
import Cheackerboard from "../../Pages/webinar/Cheackerboard";
import Approved from "../../Pages/webinar/Cheackerboard/Approved";
import Declined from "../../Pages/webinar/Cheackerboard/Declined";
import PastApproved from "../../Pages/webinar/Cheackerboard/PastApproved";
import PastDeclined from "../../Pages/webinar/Cheackerboard/PastDeclined";
import Pastwebinar from "../../Pages/webinar/Cheackerboard/Pastwebinar";
import EditWebinar from "../../Pages/webinar/EditWebinar";
import Makerboard from "../../Pages/webinar/Makerboard";
import PeoplePage from "../../Pages/webinar/PeoplePage.jsx";
import ProfilePassword from "../../Pages/webinar/Profile/CurrentPassword";
import Updatepassword from "../../Pages/webinar/Profile/CurrentPassword/Updatepassword";
import Profile from "../../Pages/webinar/Profile/PersonalInformation";
import UpdateProfile from "../../Pages/webinar/Profile/PersonalInformation/UpdateProfile";
import Registerpage from "../../Pages/webinar/Registerpage";
// editwebinar
// registerpage
const MainWrapper = () => {
  return (
    <>
      <div className="mainWrapper pt-5">
        <Routes>
          <Route path="/" element={<Webinar />} />
          <Route path="/webinar" element={<Webinar />} />
          <Route path="/registerpage" element={<Registerpage />} />
          <Route path="/editwebinar" element={<EditWebinar />} />
          <Route path="/analysis" element={<Analysis />} />
          <Route path="/activitymonitor" element={<ActivityMonitor />} />
          <Route path="/makerboard" element={<Makerboard />} />
          <Route path="/cheackerboard" element={<Cheackerboard />} />
          <Route path="/pastwebinar" element={<Pastwebinar/>}/>
          <Route path="/approved" element={<Approved />} />
          <Route path="/declined" element={<Declined />} />
          <Route path="/pastapproved" element={<PastApproved />} />
          <Route path="/pastdeclined" element={<PastDeclined />} />
          <Route path="/peoplepage" element={<PeoplePage />} />
          <Route path="/profile/changepassword" element={<ProfilePassword />} />
          <Route path="/profile/updatepassword" element={<Updatepassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/update" element={<UpdateProfile />} />
          <Route path="/*" element="not found" />
        </Routes>
      </div>
    </>
  );
};

export default MainWrapper;
