import webinar from "../../Assets/image/Logos/webinar.svg";
import maker from "../../Assets/image/Logos/maker.svg";
import checker from "../../Assets/image/Logos/checker.svg";
import people from "../../Assets/image/Logos/people.svg";
import profile from "../../Assets/image/Logos/profile.svg"
import logout from "../../Assets/image/Logos/logout.svg"
import webinar2 from "../../Assets/image/Logos/webinar2.svg";
import maker2 from "../../Assets/image/Logos/maker2.svg";
import checker2 from "../../Assets/image/Logos/checker2.svg";
import people2 from "../../Assets/image/Logos/people2.svg";
import profile2 from "../../Assets/image/Logos/profile2.svg"
// import logout2 from "../../Assets/image/Logos/logout2.svg"

const SIDEBAR_UL = [
  {
    id: 1,
    label: "Webinar",
    ref: "webinar",
    path: "/webinar",
    src: webinar,
    src1: webinar2,

  },
  {
    id: 2,
    label: "Maker",
    ref: "maker",
    path: "/makerboard",
    src: maker,
    src1: maker2,
  },

  {
    id: 3,
    label: "Checker",
    ref: "checker",
    path: "#",
    src: checker,
    src1: checker2,
    sublist: [
      {
        id: 1,
        label: "Approval Pending  ",
        path: "/cheackerboard ",
        ref: "cheaker",
      },
      {
        id: 2,
        label: "Approved ",
        path: "/approved",
        ref: "approved",
      },
      {
        id: 3,
        label: "Declined ",
        path: "/declined",
        ref: "declined",
      }
    ],
  },
  {
    id: 4,
    label: "People",
    ref: "peoplepage",
    path: "/peoplepage",
    src: people,
    src1: people2,

  },
  {
    id: 5,
    label: "Profile",
    ref: "profile",
    path: "/profile",
    src: profile,
    src1: profile2,

  },
  {
    id:6,
    label: "Logout",
    ref: "support",
    path: "#",
    src: logout,
    // src1: logout2,

  },
];


export const SIDEBAR_UL_USER = [
  {
    id: 1,
    label: "Webinar",
    ref: "webinar",
    path: "/webinar",
    src: webinar,
    src1: webinar2,

  },
  {
    id: 2,
    label: "Maker",
    ref: "maker",
    path: "/makerboard",
    src: maker,
    src1: maker2,
  },

  {
    id: 5,
    label: "Profile",
    ref: "profile",
    path: "/profile",
    src: profile,
    src1: profile2,

  },
  {
    id: 6,
    label: "Logout",
    ref: "support",
    path: "#",
    src: logout,
    // src1: logout2,

  },
];


export default SIDEBAR_UL;
