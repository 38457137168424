import axios from 'axios'
import { Create_User } from '../../Utils/Routes';

const usercreate = async (userData) => {
    var token = localStorage.getItem("token");
    try {
        const response = await axios.post(Create_User, userData,{
            headers: {
                "Content-Type": "application/json" ,
              Authorization: `Bearer ${token}`
            },
          }
          ,{
            withCredentials: true
          }
         )
        
        if (response.status === 200) {
            return { res: response.data }
        } else {
            return response.data;
        }

    } catch (err) {
        if (err.response) throw err.response.data;
        else throw err.message;
    }

}

const UserMake = {
    usercreate
}

export default UserMake;



  