import React, { useCallback, useState, useEffect } from "react";
import Input from "@mui/material/Input";
import logo from "../../Assets/image/icons/copyingtext.png";
import attachfile from "../../Assets/image/icons/attachfile.png";
import { useDropzone } from "react-dropzone";
import TextField from "@mui/material/TextField";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useForm } from "react-hook-form";
import { WebinarEdit } from "../../Utils/services";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const ariaLabel = { "aria-label": "description" };
const EditWebinar = () => {
  const location = useLocation();
  const form = new FormData();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(location?.state?.props);
  const [image, setimage] = useState(null);
  const [start, setstart] = useState(dayjs(formData?.webinarOn));
  const [date, setdate] = useState(dayjs(formData?.webinarOn).format('YYYY-MM-DD HH:mm'))
  const [time, settime] = useState(dayjs(formData?.webinarOn).format('HH:mm'))

  useEffect(() => {
    if (location) {
    }

  }, [])

  const handleDateChange = (newValue) => {
    const newDateTime = dayjs(newValue).set('hour', start.hour()).set('minute', start.minute());
    setstart(newDateTime);
    setdate(newDateTime.format('YYYY-MM-DD HH:mm'))
  };

  const handleTimeChange = (newValue) => {
    const newDateTime = dayjs(start).set('hour', newValue.hour()).set('minute', newValue.minute());
    setstart(newDateTime);
    settime(newDateTime.format('HH:mm'))
    setdate(newDateTime.format('YYYY-MM-DD HH:mm'))
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [valueable, setValueable] = useState();
  const [copied, setCopied] = useState();
  const [file, setFiles] = useState();
  const [text, setText] = useState(true);
  const [exc, setexc] = useState([]);
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: {
        "application/pdf": [".pdf"],
        "image/jpeg": [".jpeg", ".png", ".jpg"],
      },
      onDrop: (acceptedFiles) => {
        setimage(acceptedFiles[0])
        setFiles(<li key={acceptedFiles[0].path}>{acceptedFiles[0].path}</li>);
        setText(false);
      },
    });

  const onSubmit = async (evt) => {
    form.append("title", formData.Title);
    form.append("host", formData.host);
    form.append("link", formData.link);
    form.append("description", formData.description);
    form.append("webinarOn", date);
    if (image != null) {
      form.append("imageName", image)
    }
    form.append("langID", formData.langID);
    form.append("webId", formData.webId)
    const result = await WebinarEdit(form);
    toast.success(result.res.message);
    navigate("/webinar");
  };

  const onInputChange = (evt) => {
    let { name, value } = evt.target;
    setFormData({ ...formData, [name]: value });
  };

  const otherChange = (e) => {
    setValueable(e.target.value, { copied: true });
  };
  const otherCopy = () => setCopied(true);
  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 500);
  }, [copied]);

  const languages = [
    { id: 5, label: "English" },
    { id: 7, label: "Hindi" },
    { id: 8, label: "Punjabi" },
    { id: 9, label: "Tamil" },
    { id: 10, label: "Telugu" },
    { id: 11, label: "Oriya" },
    { id: 12, label: "Marathi" },
    { id: 13, label: "Bengali" },
    { id: 14, label: "Kannada" },
  ];

  return (
    <>
      <div>
        <h1 className="editwebianr1">Edit Webinar</h1>
      </div>
      <div className="">
        <form
          action=""
          onSubmit={handleSubmit(onSubmit)}
          method="post"
          onChange={onInputChange}
        >
          <div className="row ">
            <div className="col-lg-7 col-md-8 col-sm-12 col-12">
              <div className="mt-4  webinarinputtext">
                <div>
                  <p>Webinar Title</p>
                  <Input
                    placeholder="type something..."
                    inputProps={ariaLabel}
                    className="webinartitleinput mt-3"
                    name="Title"
                    value={formData?.Title}
                    {...register("Title", { required: true, maxLength: 20 })}
                  />
                  {errors?.Title?.type === "required" && (
                    <p className="validationform">Please enter the title</p>
                  )}
                  {errors?.Title?.type === "maxLength" && (
                    <p className="validationform">
                      First name cannot exceed 30 characters
                    </p>
                  )}
                </div>
                <div>
                  <p className="mt-4">Webinar Host</p>
                  <Input
                    placeholder="type something..."
                    inputProps={ariaLabel}
                    className="webinartitleinput mt-3"
                    name="host"
                    value={formData?.host}
                    {...register("host", { required: true, maxLength: 20 })}
                  />
                  {errors?.host?.type === "required" && (
                    <p className="validationform">This field is required</p>
                  )}
                </div>
                <div>
                  <p className="mt-4  d-flex">Webinar Link</p>
                  <div className="d-flex">
                    <Input
                      placeholder="Type something..."
                      className="webinartitleinput mt-3"
                      name="link"
                      value={formData?.link}
                      onChange={(e) => setexc({ ...exc, link: e.target.value })}
                      {...register("link", { required: true, maxLength: 20 })}
                    />
                    {errors?.link?.type === "required" && (
                      <p className="validationform">Please enter a link</p>
                    )}
                    <div className="imagerelativre">
                      <CopyToClipboard text={formData?.link}>
                        <img
                          src={logo}
                          alt="Copy link"
                          className="ms-3 mt-3"
                          onClick={() => setCopied(true)}
                        />
                      </CopyToClipboard>
                      {copied && <span className="imageposition">Copied</span>}
                    </div>
                  </div>
                  {/* {errors?.link?.type === "required" && <p className="validationform">Link is required</p>} */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12  mt-5">
              <div className="d-flex align-items-center justify-content-center">
                <div className="browserbox d-flex align-items-center justify-content-center">
                  <div className="browserboxinner d-flex align-items-center justify-content-center">
                    <div className="uploadfile">
                      <div>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} name="image" />
                          {isDragActive ? (
                            <p>Drop Image Here</p>
                          ) : (
                            <>
                              <div className="browsertext">
                                <img
                                  src={attachfile}
                                  value={formData?.myImageName}
                                  alt=".."
                                  className="ms-3"
                                />{" "}
                                <p>Browser</p>
                              </div>
                            </>
                          )}
                          {isDragReject && "File type not accepted, sorry!"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-1">
                {text ? (
                  <h1 className="pdfjpeg">PDF,JPEG,etc</h1>
                ) : (
                  <ul className="text-center">{file}</ul>
                )}
              </div>
            </div>
          </div>
          <div className="webinarinputtext  mt-4">
            <p>Choose Language</p>
          </div>
          <div className="row ">
            <div className="col-md-11 mt-3 languagesover">
              <ul className="ux-btn-group">
                <ul className="ux-btn-group">
                  {languages.map((language) => (
                    <li key={language.id}>
                      <input
                        type="radio"
                        id={language.id}
                        name="langID"
                        className="ux-btn-group-item"
                        // value={formData?.Language}
                        value={language.id.toString()}
                        defaultChecked={language.id === formData.langID}
                        {...register("langID", { required: true })}
                      />
                      <label htmlFor={language.id}>{language.label}</label>
                    </li>
                  ))}
                </ul>
              </ul>
              {errors?.option?.type === "required" && (
                <span>This field is required</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-10">
              <div className="webinarinputtext  mt-2 ">
                <p>Description</p>
                <div className=" mt-2">
                  <textarea
                    rows="5"
                    className="textarea"
                    name="description"
                    {...register("description", {
                      required: true,
                    })}
                    value={formData?.description}
                  ></textarea>
                </div>
                {errors?.description?.type === "required" && (
                  <p className="validationform">Please enter description</p>
                )}
              </div>
            </div>
          </div>
          <div className="webinarinputtext  mt-3 ">
            <p>Schedule Date & Time</p>
            <div className="mt-3">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack spacing={2}>
                  <div className=" datetimepicker">
                    <div>
                      <DesktopDatePicker
                        id="fromcalendar-mi"
                        label="From"
                        {...getInputProps()}
                        value={start.format('YYYY-MM-DD')} // set the value to exc.webinarOn
                        minDate={dayjs("2017-01-01")}
                        name="date"
                        onChange={handleDateChange}
                        renderInput={(params) => (
                          <TextField {...params} className="myDatePicker" />
                        )}
                      />
                      {errors?.date?.type === "required" && (
                        <p className="validationform">Please select date</p>
                      )}
                    </div>
                    <div className=" imepickerr">
                      <TimePicker
                        value={start}
                        label="Time"
                        name="time"
                        onChange={handleTimeChange}
                        {...register("time", { required: true, maxLength: 20 })}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                  </div>
                </Stack>
              </LocalizationProvider>
            </div>
          </div>
          <div className="mt-4 sacecanclebtn">
            <button type="submit" className="savebuttonedit" onClick={onSubmit}>
              Save
            </button>
            <button className="canclebuttonedit ms-4" onClick={() => { navigate('/') }}>Cancel</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditWebinar;