import * as React from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import ModalClose from "@mui/joy/ModalClose";
import UserMake from "../../Redux/user/userService";
import { toast } from 'react-toastify';
import { useForm } from "react-hook-form";

export default function PeopleAddModel({ open, setOpen, getAllUsers }) {
  const [formData, setFormData] = React.useState({});
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const form = new FormData();

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitform = async (e) => {
    console.log("hello");
    // e.preventDefault();
    const result = await UserMake.usercreate(formData);
    toast.success(result.res.message);
    setOpen(false);
    getAllUsers();
  };

  const onInputChange = (evt) => {
    let { name, value } = evt.target;
    if (name === "contactNumber") {
      value = value.slice(0, 10); // Limit to maximum 10 digits
    }
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <React.Fragment>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={open}
          onClose={() => setOpen(false)}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 650,
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
            }}
          >
            <ModalClose
              variant="outlined"
              sx={{
                top: 'calc(-1/4 * var(--IconButton-size))',
                right: 'calc(-1/4 * var(--IconButton-size))',
                boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                borderRadius: '50%',
                border: '#808191',
                border: 2,
                bgcolor: 'white',
                color: '#808191'
              }}
            />
            <div id="modal-desc" textColor="text.tertiary">
              <div className="peopleaddmodel ">
                <div className="peoplelabin ">
                  <form onSubmit={handleSubmit(onSubmitform)} onChange={onInputChange}>
                    <div className="">
                      <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                          <div className="">
                            <p>First Name</p>
                            <input type="text" className="form-control" placeholder="Samriti" name="firstName" />
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 modelnamelast">
                          <div>
                            <p>Last Name</p>
                            <input type="text" className="form-control" placeholder="Sharma" name="lastName" />
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                          <div className="">
                            <p>Contact Number</p>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="+91-8506073332"
                              name="contactNumber"
                              maxLength="10"
                              {...register("contactNumber", {
                                required: true,
                                pattern: /^[0-9]{10}$/,
                              })}
                            />
                            {errors.contactNumber?.type === "required" && (
                              <p className="validationform" style={{ color: 'red' }}>Please enter a 10-digit number</p>
                            )}
                            {errors.contactNumber?.type === "pattern" && (
                              <p className="validationform" style={{ color: 'red' }}>Please enter a valid 10-digit number</p>
                            )}
                          </div>


                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mt-3">
                          <div>
                            <p>Email</p>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="samritisharma@gmail.com"
                              name="email"
                            />
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  col-12 mt-3 peoplelabines">
                          <p>Authority</p>
                          {/* <div className="catactionpd d-flex"> */}

                          <ul class="ux-btn-group">
                            <li>
                              <input type="radio" id="Checker" class="ux-btn-group-item " value="2" name="roleId" />
                              <label for="Checker">Checker</label>
                            </li>
                            <li>
                              <input type="radio" id="Maker" class="ux-btn-group-item" value="1" name="roleId" />
                              <label for="Maker">Maker</label>
                            </li>

                          </ul>
                          {/* </div> */}
                        </div>

                        <div className="row ">
                          <div className="col-12 d-flex mt-2">
                            <div className="col-6">
                              <button
                                className="modlecanclebtn d-flex align-items-center justify-content-center"
                                onClick={handleClose}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="col-6">
                              <button className="modeldeletebtn" type="submit">Add</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Sheet>
        </Modal>
      </React.Fragment>
    </>
  );
}
