import React from "react";
import logo from "../../Assets/image/Rbstore/storeimg.png"
import moment from 'moment';
const CardWebinarProduct = ({ Title, Language,image, webId, host, webinarStartTime, webinarOn, webinarEndTime }) => {
  return (
    <div className="card webinar">
      <div className="card-body  webinarbody">
        <div className=" webinardata">
          <div className="abcd">
            <div className="image-webinar">
              <img src={image} alt="" />
            </div>
          </div>
          <div className="contant-webinar">
            <p className="contein-webinar">{Title}</p>

            <p className="collateral-detailss-webinar mt-2">{host}</p>
            <p className="collateral-detailss-webinar mt-2">
              {moment(webinarOn).format('DD-MM-YYYY')}
            </p>
            <p className="collateral-detailwebinar mt-2">{webinarStartTime}- {webinarEndTime} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardWebinarProduct;